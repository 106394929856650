// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { onSnapshot,  doc } from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [coordenadas, setCoordenadas] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setCoordenadas({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        setError(null);
      },
      (err) => {
        setError(err.message);
      }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);


  const [entregadorCollection, setentregadorCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startentregadorListener(setentregadorCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startentregadorListener = (setentregadorCollection) => {
    const entregadorRef = doc(db, "entregadores", currentUser.uid);

    const entregadorListener = onSnapshot(entregadorRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setentregadorCollection com os dados do documento
        setentregadorCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setentregadorCollection como null ou um valor padrão, conforme necessário
        setentregadorCollection(null);
      }
    });

    return entregadorListener;
  };

  const [statusGeral, setStatusGeral] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startStatusGeralListener(setStatusGeral);
      return () => unsubscribe();
    }
  }, [currentUser]);
  
  const startStatusGeralListener = (setStatusGeral ) => {
  
     //const documentoRef = doc(db, 'statusGeral2', 'HoX5CZjstwi1RnArV6bZ');
     const documentoRef = doc(db, 'statusGeral', '7aR3cmTSMUYkM9mcIRaD');
  
    const statusGeralListener = onSnapshot(documentoRef, (doc) => {
      if (doc.exists()) {
        setStatusGeral(doc.data());
      } else {
        console.log("Documento não encontrado");
      }
    }, (error) => {
      console.error("Erro em onSnapshot:", error);
    });
  
    return statusGeralListener;
  };


  const value = {
    currentUser,
    entregadorCollection,
    coordenadas,
    statusGeral,
    error // Adicionando erro ao contexto
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};