import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Importe o contexto
import './Spinner.css';

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
    <p className="loading-text">Carregando...</p>
  </div>
);

const PrivateRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth(); // Use o hook useAuth para acessar o currentUser

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          currentUser ? (
            element
          ) : (
            <Navigate to="/login" replace={true} />
          )
        }
      />
    </Routes>
  );
};

export default PrivateRoute;
